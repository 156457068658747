.tf-shutter {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: #0000003d;
  position: fixed;
  top: 0;
  left: 0;
}

.tf-modal-window {
  width: 95vw;
  height: 95vh;
  z-index: 100;
  padding: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tf-modal-window-content {
  max-height: 90%;
  background-color: #f8f8f8;
  padding: 16px;
  display: block;
  overflow-y: auto;
}

.tf-modal-window-header {
  width: 100%;
  color: #f3f3f3;
  background-color: #1e87f0;
  margin: 0;
  padding: 12px 16px;
  display: block;
}

.tf-modal-window-close {
  color: #eee;
  float: right;
  cursor: pointer;
  margin: 4px 18px 0 0;
  padding: 0;
  font-size: 28px;
  font-weight: bold;
}

.tf-modal-window-close:hover {
  color: tomato;
}

.tf-modal-window-footer {
  clear: both;
  max-height: 100px;
  overflow: none;
  text-align: right;
  background-color: #f8f8f8;
  padding: 12px;
  display: block;
}

/*# sourceMappingURL=index.e5d8be21.css.map */
