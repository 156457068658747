.tf-shutter {
    position: fixed;
    background-color: #0000003d;
    backdrop-filter: blur(4px);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
}

.tf-modal-window {
    position: fixed;
    width: 95vw;
    height: 95vh;
    padding: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.tf-modal-window-content {
    display: block;
    padding: 16px;
    background-color: #f8f8f8;
    overflow-y: auto;
    max-height: 90%;
}

.tf-modal-window-header {
    display: block;
    margin: 0;
    padding: 12px 16px;
    width: 100%;
    background-color: #1e87f0;
    color: #f3f3f3;
}

.tf-modal-window-close {
    color: #eee;
    float: right;
    padding: 0;
    margin: 0;
    font-size: 28px;
    margin-right: 18px;
    margin-top: 4px;
    font-weight: bold;
    cursor: pointer;
}

.tf-modal-window-close:hover {
    color: tomato;
}

.tf-modal-window-footer {
    clear: both;
    display: block;
    padding: 12px;
    max-height: 100px;
    background-color: #f8f8f8;
    overflow: none;
    text-align: right;
}
/* 
@media (max-width: 600px) {

    .tf-modal-window {
        width: 98%;
    }
    
    .tf-modal-window-content {
        padding: 12px;
    }
} */